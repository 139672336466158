import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useGetFavoriteSpacesQuery } from '@/generated-types/graphql.types';
import SpaceCard from '@/Search/components/SpaceCard.vue';
export default defineComponent({
    components: {
        SpaceCard
    },
    setup() {
        const allFavoriteSpaces = ref([]);
        const { onResult, refetch } = useGetFavoriteSpacesQuery();
        onResult((result) => {
            allFavoriteSpaces.value = result?.data?.customer_favorite_spaces.map(space => {
                return {
                    images: space.space_images.map(image => image && image.image_url),
                    bookingType: space.space_booking_type,
                    venueName: space.venue.venue_name,
                    features: space.space_features.map(el => el.feature_id),
                    activities: space.space_categories.map(category => category && category.category_id),
                    spaceName: space.space_name,
                    spaceUrl: space.space_url,
                    spaceId: space.space_id,
                    internalName: space.internal_name,
                    halfDayPrice: space.half_day_price,
                    spaceSize: space.space_size,
                    isAfterpayAllowed: space.is_afterpay_allowed,
                    attendees: {
                        minGuests: space.min_guests,
                        maxGuests: space.max_guests
                    },
                    cityName: space.venue.venue_geo.city.seo_entity_name,
                    cityUrl: space.venue.venue_geo.city.seo_entity_slug,
                    organisationRating: space.organisation_rating?.score || 0,
                    isNew: space.is_new,
                    isFavorite: true
                };
            });
        });
        onMounted(() => {
            refetch();
        });
        return {
            allFavoriteSpaces
        };
    }
});
